
  
  .list {
    display: flex;
    flex-direction: column;
  }
  
  .item {
    display: flex;
    align-items: center;
    padding: 5px 0;
    /* border-bottom: 1px solid #ccc; */
  }
  

  
  .dateText {
    font-family: 'OpenSans', sans-serif;
    color: gray;
    font-size: 12px;
  }
  
  .firstItem {

  }
  
  .icon {
    margin-right: 10px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
  }
  