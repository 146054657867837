.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
  }
  
  .pagination li {
    margin-right: 5px;
  }
  
  .pagination a {
    text-decoration: none;
    background-color: #e996a6;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
    cursor: pointer; /* Змінюємо курсор на пальця */
  }
  
  .pagination a:hover {
    background-color: #9b7793;
  }
  
  .pagination .active a {
    background-color: #9b7793;
  }
  
  