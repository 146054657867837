/* src/leaflet.css */
@import '~leaflet/dist/leaflet.css';

.leaflet-container {
    /* height: 100%;
    width: 100%; */
  }
  
  .leaflet-marker-icon {
    background-image: url('~leaflet/dist/images/marker-icon.png');
  }
  
  .leaflet-marker-icon.leaflet-retina {
    background-image: url('~leaflet/dist/images/marker-icon-2x.png');
  }
  
  .leaflet-marker-shadow {
    background-image: url('~leaflet/dist/images/marker-shadow.png');
  }
  