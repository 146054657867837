.idle {
  background-color: #FF342E;
}

.city-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.truck-list-item{
  /* padding: 10px; */
  /* padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 0px; */
  /* border-bottom: 1px solid black; */
  background-color: #F4F6F9;
  margin-bottom: 3px;
  padding: 5px 5px 5px 0px;
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.routesItem:hover {
  font-weight: inherit;
  text-decoration: none;
}

.trip-list-item {
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  /* border: 1px solid black; */
}