/* Загальний селектор для рядка */
.clientTripsTableRow:hover {
    background-color: #e996a6;
    /* Змінюємо фон рядка при наведенні */
}

/* Горизонтальні границі для всіх колонок */
.clientTripsTableRow {
    border-bottom: 1px solid rgb(243, 195, 195);
}

/* Горизонтальні границі для всіх колонок, крім останньої і передостанньої */
.clientTripsTableRow> :not(:last-child):not(:nth-last-child(2)) {
    border-right: 1px dotted #e996a6;
}

/* Змінюємо границі на білі при наведенні на рядок */
.clientTripsTableRow:hover> :not(:last-child):not(:nth-last-child(2)) {
    border-right: 1px dotted #ffffff;
}


/* Горизонтальні границі для всіх колонок, крім останньої */
.clientTripsTableRowAddress> :not(:last-child) {
    border-right: 1px dotted #e996a6;
}

/* Змінюємо границі на білі при наведенні на рядок */
.clientTripsTableRowAddress:hover> :not(:last-child) {
    border-right: 1px dotted #ffffff;
}

.clientTripsTableColumnHeader {
    cursor: default;
}