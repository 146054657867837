.info-block {
    /* flex-direction: row; */
    font-size: small;
    flex: 1;
    height: 100%;

}



.checkpoint {
    flex-direction: row;
}

.person { /*не трогать*/
    display: flex;
}

.person-icon { /*не трогать*/
    margin-right: 5px;
}

.buttons {
    /* position: absolute;
    right: 0px;
    top: 0px; */
}

.tooltip {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Другие стили */
}

.cities {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.dates {
    display: flex;
    flex-direction: column;
    padding: 5px;
}


.status {
    padding-left: 2px;
    font-size: smaller;
    display: flex;
    justify-content: space-between;
  }
  

.trip-card-point {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    /* position: relative; */
    /* font-size: smaller; */
}