#root {
  background-color:#F2F5FD;

  /*
  #e996a6 - розовый
  #9b7793 - фиолет
  #101820 - черный
  */ 
}
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

body {
/* overflow: hidden; */
}

.appBar {
  height: 50px;
  justify-content: center;
}


.scroll-container {
  overflow-y: auto; /* Включает вертикальную прокрутку при необходимости */
  height: 100vh; /* Задайте желаемую высоту контейнера */
  /* Дополнительные стили, если необходимо */
}

/* Стили для скроллбара */
.scroll-container::-webkit-scrollbar {
  width: 8px; /* Ширина скроллбара */
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Цвет фона трека скроллбара */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Цвет полосы скроллбара */
  border-radius: 4px; /* Радиус скругления полосы скроллбара */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Цвет полосы скроллбара при наведении */
}






