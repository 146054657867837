.table-header {
  background-color:#e996a6;
  /* position: absolute;
  top: 0px;
  left: 0px; */
}


.table-row {
  /* padding: 1px 0px 1px 0px; */
  border-bottom: 1px solid rgb(77, 63, 63);
  cursor: pointer;
}
.table-row:hover {
  background-color: blanchedalmond;
  outline: 2px solid rgb(24, 24, 24);
  outline-offset: -1px;
  z-index: 200;
}

.row-cell {
  border-right: 1px solid rgb(63, 71, 77);

}
.category {

  color: black;
  border-bottom: 1px solid black;
}

.tooltip {
 overflow: hidden;
  /* Другие стили */
}

.comment {
  height: 100%;
}

.truck-info{
  padding: 0px 0px 0px 5px;
  font-size: small;
  height: 100%;
}